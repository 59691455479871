import {
  ChakraProvider,
  Heading,
  Container,
  Center,
  Select,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  HStack,
  VStack,
  NumberInput,
  NumberInputField,
  Box,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useDevices, useDevice, resetData, finetuneModel } from "./backend";
import { Scatter } from "react-chartjs-2";
import { NavBar } from "./components/NavBar";

const SpecializedScatter = ({ deviceId, samplesPerSecond, threshold }) => {
  const { data: device } = useDevice({ deviceId, samplesPerSecond });

  if (device === undefined) {
    return null;
  }

  return (
    <Box minWidth="700px">
      <Scatter
        data={{
          datasets: [
            {
              label: "Reconstruction loss",
              data: device.predictions.map((loss, index) => ({
                x: index,
                y: loss,
              })),
              backgroundColor: "rgba(255, 99, 132, 1)",
            },
            {
              type: "line",
              label: "Anomaly threshold",
              borderColor: "rgb(54, 162, 235)",
              borderWidth: 2,
              fill: false,
              data: [
                { x: 0, y: threshold },
                { x: device.predictions.length - 1, y: threshold },
              ],
              tooltip: false,
              pointRadius: 0,
            },
          ],
        }}
        options={{
          scales: {
            y: {
              beginAtZero: true,
            },
            x: {
              max: device.predictions.length - 1,
            },
          },
        }}
      />
    </Box>
  );
};

export const App = () => {
  const { data: devices } = useDevices();
  const [deviceId, setDeviceId] = useState(undefined);
  const [samplesPerSecond, setSamplesPerSecond] = useState(16);
  const [threshold, setThreshold] = useState(undefined);
  const [isTraining, setIsTraining] = useState(false);

  const device = devices?.filter((device) => device.id === deviceId)[0];

  const clickResetData = () => {
    if (deviceId) {
      resetData(deviceId);
    }
  };

  const clickFinetuneModel = () => {
    if (deviceId) {
      setIsTraining(true);
      finetuneModel(deviceId).then(() => {
        setIsTraining(false);
      });
    }
  };

  useEffect(() => {
    if (deviceId === undefined && devices && devices.length >= 1) {
      setDeviceId(devices[0].id);
    }
  }, [deviceId, devices]);

  useEffect(() => {
    if (threshold === undefined && device) {
      setThreshold(device.threshold);
    }
  }, [threshold, device]);

  if (devices === undefined) {
    return null;
  }

  return (
    <ChakraProvider>
      <NavBar />
      <Center>
        <Container maxWidth="container.lg" marginTop="20px">
          <Heading size="sm" margin="4px 0px">
            Select device
          </Heading>
          <Select
            onChange={(event) => {
              setDeviceId(event.target.value);
            }}
            marginBottom="12px"
          >
            {devices.map((device) => (
              <option key={device.id} value={device.id}>
                {device.id}
              </option>
            ))}
          </Select>
          <HStack spacing={2} marginBottom="30px">
            <Button onClick={clickResetData} isDisabled={isTraining}>
              Reset data ({device?.n_seconds_audio} seconds)
            </Button>
            <Button
              onClick={clickFinetuneModel}
              isLoading={isTraining}
              loadingText="Training model"
            >
              Train model
            </Button>
          </HStack>
          {device && (
            <HStack>
              <SpecializedScatter
                deviceId={deviceId}
                samplesPerSecond={samplesPerSecond}
                threshold={threshold}
              />
              <VStack align="left" spacing={4}>
                <Box>
                  <Heading size="sm" margin="4px 0px">
                    Samples per second ({samplesPerSecond})
                  </Heading>
                  <Slider
                    aria-label="samples-per-second"
                    defaultValue={samplesPerSecond}
                    onChangeEnd={setSamplesPerSecond}
                    min={1}
                    max={100}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Box>
                <Box>
                  <Heading size="sm" margin="4px 0px">
                    Anomaly threshold
                  </Heading>
                  <NumberInput
                    value={threshold}
                    precision={2}
                    onChange={setThreshold}
                  >
                    <NumberInputField />
                  </NumberInput>
                </Box>
                <Box>
                  <Heading size="sm" margin="4px 0px">
                    Minimum number of anomalies during time interval before
                    warning
                  </Heading>
                  <NumberInput defaultValue={10}>
                    <NumberInputField />
                  </NumberInput>
                </Box>
                <Box>
                  <Heading size="sm" margin="4px 0px">
                    Time interval (s)
                  </Heading>
                  <NumberInput defaultValue={120}>
                    <NumberInputField />
                  </NumberInput>
                </Box>
              </VStack>
            </HStack>
          )}
        </Container>
      </Center>
    </ChakraProvider>
  );
};
