import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Box,
} from "@chakra-ui/react";

export const NavigationMenu = () => {
  return (
    <Box marginLeft="auto" paddingLeft="10">
      <Menu>
        <MenuButton marginLeft="auto">
          {/* Hack to get a nice color */}
          <Avatar size="md" name={"Filip Johansson    "} />
        </MenuButton>
        <Portal>
          <MenuList zIndex="dropdown">
            <MenuItem>Sign Out</MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};
