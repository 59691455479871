import {
  Flex,
  Heading,
  useColorModeValue,
  Spacer,
  Icon,
} from "@chakra-ui/react";
import { NavigationMenu } from "./NavigationMenu";
import { MdGraphicEq } from "react-icons/md";

export const NavBar = () => {
  return (
    <Flex
      width="100%"
      paddingRight="10"
      paddingLeft="10"
      position="sticky"
      zIndex="docked"
      top="0"
      left="0"
      bg={useColorModeValue("white", "gray.900")}
      borderBottom="1px solid"
      borderColor="blackAlpha.100"
      direction="row"
      height="70px"
    >
      <Spacer />

      <Flex
        direction="row"
        alignItems="center"
        maxWidth="container.lg"
        width="full"
      >
        <Heading size="sm">
          <Icon
            as={MdGraphicEq}
            boxSize="2em"
            style={{ position: "relative", top: "-2px" }}
          />{" "}
          Live Audio Fingerprint
        </Heading>
      </Flex>

      <Flex flex="1" alignItems="center">
        <NavigationMenu />
      </Flex>
    </Flex>
  );
};
