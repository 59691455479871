import useSWR from "swr";

const backend = "http://webhallen.freemyip.com:12000";

const fetcher = (...args) => {
  const resource = `${backend}${args[0]}`;
  return (args.length === 1 ? fetch(resource) : fetch(resource, args[1]))
    .then((response) => {
      if (response.status >= 400) {
        throw response;
      }
      return response;
    })
    .then((response) => response.json());
};

export function useDevices() {
  return useSWR("/devices", fetcher, { refreshInterval: 10000 });
}

export function useDevice({ deviceId, samplesPerSecond }) {
  let url = `/devices/${deviceId}`;
  if (samplesPerSecond) {
    url += `?samples_per_second=${samplesPerSecond}`;
  }
  return useSWR(
    deviceId ? url : null,
    fetcher,
    { refreshInterval: 5000 }
  );
}

export function finetuneModel(deviceId) {
  return fetcher(`/devices/${deviceId}/finetune`, { method: "POST" });
}

export function resetData(deviceId) {
  return fetcher(`/devices/${deviceId}/audio`, { method: "DELETE" });
}
